.link {
  color: #4183c4
}

.capitalize {
  text-transform: capitalize;
}

.loader-small {
  margin-left: 10px;
  font-size: 9pt;
  display: inline;
}